import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "goober";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";
import i18n from "i18next";
import { subscribeKey } from "valtio/utils";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_TESTING } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import type { Meta } from "@/__main__/router.mjs";
import router, { setRoute } from "@/__main__/router.mjs";
import { getRedirectUrl } from "@/app/util.mjs";
import { ContentWrapper } from "@/feature-auth/Authentication.style.jsx";
import AuthenticateForm from "@/feature-auth/components/AuthenticateForm.jsx";
import { Illustration } from "@/feature-auth/components/Illustration.jsx";
import OnboardingForm from "@/feature-auth/components/OnboardingForm.jsx";
import VerifyCode from "@/feature-auth/components/VerifyCode.jsx";
import { getOAuth2Url } from "@/feature-auth/fetches/api.mjs";
import { authRoutes } from "@/feature-auth/mod.mjs";
import type { NewUser } from "@/feature-auth/models/user-model.mjs";
import { initAuth } from "@/feature-auth/utils/auth-actions.mjs";
import * as AuthActions from "@/feature-auth/utils/auth-actions.mjs";
import { AuthClient } from "@/feature-auth/utils/auth-client.mjs";
import { isUserOnboarded } from "@/feature-auth/utils/utils.mjs";
import { useClientOnly } from "@/util/ClientOnly.jsx";
import { devError } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import adjectives from "@/vendor/adjectives.json";
import nouns from "@/vendor/nouns.json";

const FullPageLoadingSpinner = styled(LoadingSpinner)`
  height: 100%;
`;

function generateSecretCode(i = 0): string {
  // If extra entropy is needed can append numbers, I don't think it is strictly necessary
  const parts = [adjectives, nouns, [Math.floor(Math.random() * 1000)]].map(
    (list) => {
      return list[Math.floor(Math.random() * list.length)];
    },
  );

  // Re-roll if adjective matches noun
  // This is potentially infinite recursion, set a limit
  if (new Set(parts).size !== parts.length && i < 10) {
    return generateSecretCode(i + 1);
  }

  return parts.join(" ");
}

// This differs by waiting for account settings to populate.
async function getAccountReturnTo() {
  if (IS_TESTING) return;
  const settings = await new Promise((resolve, reject) => {
    subscribeKey(readState, "settings", resolve);
    setTimeout(() => {
      reject(new Error("Account settings timeout"));
    }, 5 * 1000);
  });
  return getRedirectUrl(settings, readState.localState);
}

const returnToApp = async (returnTo?: string) => {
  const path = (returnTo ??
    (await getAccountReturnTo().catch(devError)) ??
    "/") as string;
  await setRoute(path, undefined, undefined, true);
};

export default function SignIn() {
  const route = useRoute<{
    returnTo?: string;
  }>();

  const returnTo = useMemo(() => {
    let returnTo =
      (route.searchParams.get("returnTo") as string) ??
      route.state.returnTo ??
      router.previousRoute?.currentPath;

    if (!returnTo || authRoutes.some(({ path }) => path === returnTo)) {
      returnTo =
        getRedirectUrl(readState.settings, readState.localState) ?? "/";
    }

    return returnTo;
  }, [route.searchParams, route.state.returnTo]);

  const [onboarding, setOnboarding] = useState<NewUser | null>(null);

  const [email, setEmail] = useState(null);
  const [oAuthTrackerId, setOAuthTrackerId] = useState(null);
  const [secretCode, setSecretCode] = useState<string | null>(null);
  const [_error, setError] = useState<unknown>(null);

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  useEffect(() => {
    if ((email || oAuthTrackerId) && abortController) {
      (async () => {
        let secretCode: string | null = null;
        if (email) secretCode = generateSecretCode();

        try {
          abortController.signal.throwIfAborted();

          if (email) setSecretCode(secretCode);

          const authClient = new AuthClient();

          const { authToken, authTokenExpiry, user } =
            await authClient.startAuthProcess(
              email,
              i18n.language,
              secretCode,
              oAuthTrackerId,
              abortController.signal,
            );

          await AuthActions.setAuthAction({ authToken, authTokenExpiry });

          if (isUserOnboarded(user)) {
            AuthActions.setUserAction(user);

            await returnToApp(returnTo);
          } else {
            setOnboarding(user);
            setSecretCode(null);
          }
        } catch (err) {
          if (!(err instanceof DOMException) || err.name !== "AbortError") {
            devError("Failed to authenticate", err);

            setError(err);
          }
        }
      })();
    }
  }, [email, oAuthTrackerId, abortController, returnTo]);

  const sendAuthCode = useCallback(
    (email: string) => {
      abortController?.abort();

      setAbortController(new AbortController());

      setEmail(email);
    },
    [abortController, setEmail],
  );

  const startOAuth2Flow = useCallback(
    (provider: string) => {
      abortController?.abort();

      setAbortController(new AbortController());

      const uuid = crypto.randomUUID();
      setOAuthTrackerId(uuid);
      const url = getOAuth2Url(provider, uuid);
      if (IS_APP) {
        blitzMessage(EVENTS.OPEN_EXTERNAL, url);
      } else {
        globals.open(url, "_blank");
      }
    },
    [abortController, setOAuthTrackerId],
  );

  const onResend = useCallback(() => {
    sendAuthCode(email);
  }, [email, sendAuthCode]);

  const onCancel = useCallback(() => {
    abortController?.abort();
    setAbortController(null);
    setEmail(null);
  }, [abortController, setEmail]);

  const isHydrated = useClientOnly();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // When navigating away, this component gets rebuilt under the new route. If we let
    // it run in this case, it will notice the user is logged in and redirect them again.
    if (route.currentPath !== "/account") return;

    let isMounted = true;

    queueMicrotask(() => {
      if (isMounted) {
        initAuth().then(() => {
          if (readState.user) {
            // If the user is already logged in, redirect them away
            returnToApp();

            return;
          }

          setIsLoading(false);
        });
      }
    });

    return () => {
      isMounted = false;
    };
  }, [isLoading, setIsLoading, route.currentPath]);

  if (!isHydrated || isLoading) {
    return <FullPageLoadingSpinner />;
  }

  if (onboarding) {
    return (
      <ContentWrapper>
        <OnboardingForm user={onboarding} returnToApp={returnToApp} />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Illustration />

      {!email ? (
        <AuthenticateForm
          onSubmit={sendAuthCode}
          startOAuth2Flow={startOAuth2Flow}
        />
      ) : (
        <VerifyCode
          email={email}
          secretCode={secretCode}
          onResend={onResend}
          onCancel={onCancel}
        />
      )}
    </ContentWrapper>
  );
}

SignIn.fullBleed = true;
SignIn.showPageHeader = false;

export function meta(): Meta {
  return {
    title: ["common:navigation.signIn", "Sign In"],
    description: ["common:navigation.signIn", "Sign In"],
  };
}
